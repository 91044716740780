
import {ref, defineComponent} from 'vue';

import Card from 'primevue/card';

export default defineComponent({
  components: {
    Card,
  },
  setup() {
    const items = ref([
      {
        id: 1,
        label: 'Номенклатура',
        to: '/dictionaries/nomenclature',
        icon: 'pi pi-fw pi-book',
      },
      {
        id: 2,
        label: 'Поставки',
        to: '/dictionaries/lots',
        icon: 'pi pi-fw pi-directions',
      },
      {
        id: 3,
        label: 'Склады',
        to: '/dictionaries/locations',
        icon: 'pi pi-fw pi-microsoft',
      },
      {
        id: 4,
        label: 'Товары',
        to: '/dictionaries/products',
        icon: 'pi pi-fw pi-table',
      },
    ]);

    return {
      items
    }
  }
});
